@import "src/assets/styles/variables";
.cz-header {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  &__container {
    display: flex;
    justify-content: space-between;
  }
  &__right {
    display: flex;
    align-items: center;
  }
  &__nav {
    ul {
      display: inline-flex;
      text-indent: 0;
      list-style-type: none;
      li {
        margin: 5px;
        a {
          color: rgb(29, 38, 58);
          text-decoration: none;
          padding: 0 10px;

          &.active {
            color: $primary-color;
          }
        }
      }
    }
  }

  &__search-icon {
    padding: 5px;
    margin: 10px;
    cursor: pointer;
  }
}
