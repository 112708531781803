@import "src/assets/styles/variables";

body {
  margin: 0;
  color: $font-color;
  line-height: 1.76;
  font-size: 14px;
}

.dangerouslySetInnerHTML__img {
  img {
    height: auto !important;
    max-width: 100% !important;
  }
}

.home-page {
  &__goals-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 22px;
  }
}

.container {
  max-width: 1200px;
  margin: auto;
}

.cz-button {
  background-color: $primary-color;
  color: $white;
  border: unset;
  padding: 10px 20px;
  &--rounded {
    border-radius: 1rem;
  }
}

// MazzardH
// @font-face {
//   font-family: "MazzardH";
//   font-weight: 900;
//   src: local("MazzardH"),
//     url(./assets/fonts/MazzardH/MazzardH-Bold.otf) format("opentype");
// }

// @font-face {
//   font-family: "MazzardH";
//   font-weight: 700;
//   src: local("MazzardH"),
//     url(./assets/fonts/MazzardH/MazzardH-SemiBold.otf) format("opentype");
// }

@font-face {
  font-family: "MazzardH";
  font-weight: 700;
  src: local("MazzardH"),
    url(./assets/fonts/MazzardH/MazzardH-Medium.otf) format("opentype");
}

@font-face {
  font-family: "MazzardH";
  font-weight: 500;
  src: local("MazzardH"),
    url(./assets/fonts/MazzardH/MazzardH-Regular.otf) format("opentype");
}

@font-face {
  font-family: "MazzardH";
  font-weight: 300;
  src: local("MazzardH"),
    url(./assets/fonts/MazzardH/MazzardH-Light.otf) format("opentype");
}

// OpenDyslexic
@font-face {
  font-family: "OpenDyslexic";
  font-weight: "bold";
  src: local("OpenDyslexic"),
    url(./assets/fonts/OpenDyslexic/OpenDyslexic-Bold.otf) format("opentype");
}

@font-face {
  font-family: "OpenDyslexic";
  font-weight: "regular";
  src: local("OpenDyslexic"),
    url(./assets/fonts/OpenDyslexic/OpenDyslexic-Regular.otf) format("opentype");
}

blockquote {
  background-color: #eee;
  border-left: 5px solid #96bb55;
  margin: 0;
  padding: .5em 1em .5em 1em;
}

a {
  color: $secondary-color;
}

a.file-anchor {
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
}

a.file-anchor::before {
  font-family: 'FontAwesome';
  content: '\f019';
  font-weight: bold;
  color: $primary-color;
  margin-right: .5rem;
}

li::marker {
  color: $primary-color;
  font-size: 1.5rem;
}

iframe {
  max-width: 540px;
  width: 100% !important;
}